<template>
  <div id="home">
    <div class="mad-media-element type-2 fondo-principal">
      <div class="mad-media-inner">
        <!-- <h1 class="mad-media-title">Encuentra la propiedad de tus sueños</h1>
        <p>Podemos buscarte la mejor propiedad para tus necesidades.</p> -->
      </div>
    </div>
    <!--================ End of Header ================-->
    <div class="mad-content no-pd">
      <div class="container">

        <div class="mad-section with-bg-element style-3 left-side size-2 mb-5" style="margin-bottom: 45px;">
          <div class="mad-title-wrap vr-type">
            <h2 class="mad-title">Nuevas propiedades</h2>
            <p>Puedes buscar de acuerdo a tus necesidades</p>
          </div>
          <div class="mad-tabs mad-iso-tabs">
            <!--================ Tabs Navigation ================-->
            <div role="tablist" aria-label="Tabs v1" class="mad-tabs-nav">
              <span :class="{ 'mad-active': movimientoFiltrar == 'todas' }" ><a id="tab-4-link" v-on:click="getProperties('todas')" role="tab"
                  aria-selected="false" aria-controls="tab-4" class="mad-ln--independent mad-tab-link">Todas</a>
              </span>
              <span :class="{ 'mad-active': movimientoFiltrar == 'terrenos_comerciales' }" ><a id="tab-5-link" v-on:click="getProperties('todas', false, 'terreno comercial')" role="tab" aria-selected="true"
                  aria-controls="tab-5" class="mad-ln--independent mad-tab-link">Terrenos comerciales</a>
              </span>
              <span :class="{ 'mad-active': movimientoFiltrar == 'venta' }" ><a id="tab-5-link" v-on:click="getProperties('venta')" role="tab" aria-selected="true"
                  aria-controls="tab-5" class="mad-ln--independent mad-tab-link">En venta</a>
              </span>
              <span :class="{ 'mad-active': movimientoFiltrar == 'renta' }"><a id="tab-6-link" v-on:click="getProperties('renta')" role="tab" aria-selected="false"
                  aria-controls="tab-6" class="mad-ln--independent mad-tab-link">En renta</a>
              </span>
            </div>
            <!--================ End of Tabs Navigation ================-->
            <!--================ Tabs Container ================-->
            <div class="mad-tabs-container">
              <!--================ Tab ================-->
              <div id="tab-4" tabindex="1" role="tabpanel" aria-labelledby="tab-4-link" class="mad-tab">
                <div v-if="load" class="text-center mt-5">
                  <i class="fas fa-spinner fa-pulse fa-4x"></i>
                </div>
                <div v-if="properties.length < 1 && !load">
                  <h2>Lo sentimos no encontramos ninguna propiedad con ese criterio.</h2>
                  <h5 class="mad-text-green">Pero puedes contactarnos, contamos con las herramientas
                    para encontrar la
                    propiedad que buscas.</h5>
                </div>
                <template v-else>
                  <div class="mad-entities mad-entities-type-2 item-col-3">
                    <template>
                      <div class="mad-grid-item" v-for="property in properties" :key="property.id_propiedad">
                        <!--================ Entity ================-->
                        <PropiedadItem v-bind:data="property" @ver-propiedad="verPropiedad($event)" />
                        <!--================ End of Entity ================-->
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </div>
            <Pagination @changePage="cambiarPagina($event)" v-if="cargaInicial" class="mt-5" :total="dataPaginacion.total" :perPage="dataPaginacion.perPage" />
            <!--================ End of Tabs Container ================-->
          </div>
        </div>
        <article class="mad-cta mad-section size-2 mad-section--stretched mad-colorizer--scheme-">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <h2 class="mad-cta-title title-def "><span class="mad-text-green">¿Buscas algo en
                  especifico?</span>
                <br>
                <p>¡Nosotros podemos ayudarte a encontrarlo!</p>
                <a href="#contacto" class="btn btn-style-2"><span>Contactanos</span><i
                    class="fas fa-chevron-right"></i></a>
              </h2>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from '../services/http'
import PropiedadItem from '../components/PropiedadItem'
import Pagination from '../components/Pagination'
export default {
  components: {
    PropiedadItem,
    Pagination
  },
  data () {
    return {
      properties: [],
      load: true,
      showPropiedad: false,
      propiedad: {},
      movimientoFiltrar: '',
      paginaFiltro: 1,
      porPagina: 9,
      dataPaginacion: {
        total: 0,
        perPage: 0
      },
      cargaInicial: true
    }
  },
  created () {
    this.getProperties('todas', true)
  },
  methods: {
    verPropiedad (e) {
      this.propiedad = e
      this.showPropiedad = true
    },
    filterProperties (movimiento, tipo) {
      this.movimientoFiltrar = movimiento
      this.tipoFiltrar = tipo

      const params = new URLSearchParams({
        page: this.paginaFiltro,
        per_page: this.porPagina
      })

      console.log({ movimiento, tipo })

      if (movimiento !== 'todas') {
        params.set('mov', movimiento)
      }

      if (tipo !== 'tipo') {
        params.set('type', tipo)
      }

      return params
    },
    async getProperties (movimiento, cargaInicial = true, tipo = '') {
      this.load = true
      this.properties = []
      this.movimiento = movimiento
      if (cargaInicial) {
        this.cargaInicial = false
        this.properties = []
        this.paginaFiltro = 1
        this.porPagina = 9
      }
      try {
        const params = this.filterProperties(movimiento, tipo)
        const { data } = await HTTP.get(`/properties?${params}`)

        this.properties = data.properties
        this.dataPaginacion = {
          total: data.total,
          perPage: data.per_page
        }
        this.showPropiedad = false
        this.cargaInicial = true
      } catch (err) {
        console.log(err)
        alert('Ocurrio un error')
      } finally {
        this.load = false
      }
    },
    cambiarPagina (pagina) {
      this.paginaFiltro = pagina
      this.getProperties(this.movimiento, false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .fondo-principal {
    background-image: url('../assets/images/fondo_landing_page_bunker_movil.jpg');
  }
  .mad-media-element.type-2 {
    min-height: 25rem;
  }

  @media (min-width: 375px) {
    .mad-media-element.type-2 {
      min-height: 30rem;
    }
  }

  @media (min-width: 768px) {
    .fondo-principal {
      background-image: url('../assets/images/fondo_landing_page_bunker.jpg');
    }
    .mad-media-element.type-2 {
      min-height: 33rem;
    }
  }
  .mad-tab-link {
    cursor: pointer;
  }
  .with-bg-element.size-2.left-side:after {
    max-width: 100%;
  }
</style>
