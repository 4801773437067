<template>
    <article class="mad-entity" >
        <div class="mad-entity-media" v-on:click="getIndex">
            <div class="mad-grid mad-grid--cols-1 with-nav-2" :style="estilos"></div>
        </div>
        <div class="mad-entity-content" :style="background_opacity">
            <header class="mad-entity-header">
                <a class="mad-entity-price">{{data.precio_sugerido | money }}</a>
                <a class="mad-entity-type">Propiedad en {{data.tipo_movimiento}}</a>
            </header>
            <footer class="mad-entity-footer">
                <p class="mad-adress">{{data.domicilio}}</p>
                <div class="mad-entity-stat">
                <span>{{data.numero_habitaciones}} <br> <span>Rec</span></span>
                <span>{{data.numero_banios}} <br> <span>ba</span></span>
                <span>{{data.numero_niveles}} <br> <span>Niv</span></span>
                </div>
            </footer>
        </div>
    </article>
</template>
<script>
const accounting = require('accounting-js')
export default {
  props: ['data'],
  data: function () {
    return {
      estilos: {
        'background-image': `url('${this.getPhoto()}')`,
        'background-size': 'cover',
        'background-position': 'center',
        padding: '125px',
        'flex-shrink': 0,
        width: '100%'
      },
      background_opacity: {
        position: 'absolute',
        bottom: 0,
        background: 'rgba(0, 0, 0, 0.5)',
        color: '#f1f1f1',
        width: '100%',
        padding: '20px'
      }
    }
  },
  filters: {
    money: function (amount) {
      return accounting.formatMoney(amount)
    }
  },
  methods: {
    getPhoto: function () {
      const { foto_portada: fotoPortada } = this.data
      if (typeof (fotoPortada) !== 'undefined') {
        return this.data.foto_portada
      } else {
        return '../img/no_fotos.jpg'
      }
    },
    getIndex: function () {
      this.$router.push('/propiedad/' + this.data.id_propiedad)
    }
  }
}
</script>
