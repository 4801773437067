<template>
  <div id="app">
    <Navbar/>
    <router-view/>
  </div>
</template>
<script>
import Navbar from './components/shared/Navbar'
export default {
  components: {
    Navbar
  }
}
</script>
