<template>
  <div class="overflow-auto">
    <b-pagination
      v-model="currentPage"
      :total-rows="total"
      :per-page="perPage"
      prev-text="Anterior"
      next-text="Siguiente"
      size='lg'
      pills
      align="center"
    ></b-pagination>
  </div>
</template>
<script>
export default {
  name: 'Pagination',
  props: {
    total: Number,
    perPage: Number
  },
  data () {
    return {
      currentPage: 1
    }
  },
  watch: {
    currentPage () {
      this.$emit('changePage', this.currentPage)
    }
  }
}
</script>
