const axios = require('axios').default

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttprequest',
    'X-API-KEY': process.env.VUE_APP_API_KEY
  }
})
