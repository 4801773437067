<template>
  <div class="mad-content top-size-2">
    <div v-if="isLoading" class="text-center text-dark">
      <h3 class="text-muted font-weight-normal">
        Cargando propiedad...
      </h3>
    </div>

    <div v-else class="mad-property-section mad-hide-tabs">
      <div class="mad-tabs mad-tabs--style-2">
        <div class="mad-element">
          <div class="mad-ag-wrap">
            <div class="container">
              <div class="mad-property-header">
                <div class="mad-col">
                  <div class="mad-property-info">
                    <div class="mad-property-items">
                      <div class="mad-item">
                        <div class="mad-price">{{ propiedad.precio_sugerido | money }}</div>
                        <a class="mad-link link-blue"><i class="fa fa-calculator"></i>
                          Precio de {{ propiedad.tipo_movimiento }}
                        </a>
                      </div>
                      <div class="mad-item">
                        <div class="mad-title">{{propiedad.numero_habitaciones}}</div>
                        Habitaciones
                      </div>
                      <div class="mad-item">
                        <div class="mad-title">{{propiedad.numero_banios}}</div>
                        Baños completos
                      </div>
                      <div class="mad-item">
                        <div class="mad-title">{{propiedad.medios_banios}}</div>
                        Medios baños
                      </div>
                      <div class="mad-item">
                        <div class="mad-title">{{propiedad.dimensiones_construccion}} <span>m2</span></div>
                        Dim. construcción
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mad-property-content mad-ag-wrap">
          <div class="container">
            <div class="content-element-4">
              <Slider :fotos="propiedad.fotos" />
              <div role="tablist" aria-label="Tabs v1" class="mad-tabs-nav">
                <span class="mad-active"><a id="tab-0-link" href="#tab-0" role="tab" aria-selected="true"
                    aria-controls="tab-0" class="mad-tab-link">Descripción</a>
                </span>
              </div>
            </div>
            <div class="content-element-6">
              <div class="row size-2">
                <main id="main" class="col-xl-9 col-lg-8">
                  <div class="mad-tabs-container">
                    <div id="tab-0" tabindex="0" role="tabpanel" aria-labelledby="tab-0-link" class="mad-tab">
                      <div class="content-element-6">
                        <div class="row">
                          <div class="col-md-6">
                            <h4>Descripción</h4>
                            <p>{{propiedad.observaciones}}</p>
                          </div>
                          <div class="col-md-6">
                            <h4>Detalles de la propiedad</h4>
                            <div class="mad-property-list">
                              <ul>
                                <li>
                                  <span>Tipo de propiedad</span><span>{{propiedad.tipo_propiedad}}</span>
                                </li>
                                <li>
                                  <span>Niveles</span><span>{{propiedad.numero_niveles}}</span>
                                </li>
                                <li>
                                  <span>Habitaciones</span><span>{{propiedad.numero_habitaciones}}</span>
                                </li>
                                <li>
                                  <span>Baños</span><span>{{propiedad.numero_banios}}</span>
                                </li>
                                <li>
                                  <span>Medios Baños</span><span>{{propiedad.medios_banios}}</span>
                                </li>
                                <li>
                                  <span>Cap. Cochera</span><span>{{propiedad.capacidad_cochera}}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="content-element-6">
                        <h4>Amenidades</h4>
                        <div class="content-element">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="mad-entity-content">
                                <ul v-if="propiedad.amenidades.length > 0" class="mad-list--icon">
                                  <li v-for="(item, index) in propiedad.amenidades" :key="index">
                                    <i class="fas fa-check"></i>
                                    <span>
                                      {{item}}
                                    </span>
                                  </li>
                                </ul>
                                <h6 class="text-muted" v-else>No se han especificado amenidades en esta propiedad.</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
                <aside id="sidebar" class="col-xl-3 col-lg-4">
                  <div class="mad-widget">

                    <h5 class="mad-widget-title">Contactar con el agente</h5>

                    <div class="text-center">
                      <img class="propiedad__contacto-imagen " :src="propiedad.foto_asesor"
                        :alt="'foto del asesor ' + propiedad.asesor" />
                    </div>
                    <br>

                    <small>
                      Asesor inmobiliario
                    </small>

                    <h6 class="mt-4">{{ propiedad.asesor }}</h6>

                    <label class="propiedad__contacto-informacion-title">
                      Información de contacto
                    </label>
                    <p>
                      <i class="propiedad__contacto-icon fas fa-envelope"></i>
                      {{ propiedad.correo_asesor }}
                    </p>

                    <p>
                      <i class="propiedad__contacto-icon fas fa-mobile"></i>
                      {{ propiedad.telefono_asesor }}
                    </p>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from '../services/http'
import Slider from '../components/Slider'
const accounting = require('accounting-js')
export default {
  components: {
    Slider
  },
  data () {
    return {
      isLoading: true,
      propiedad: {},
      estilos: {
        padding: '125px',
        'flex-shrink': 0,
        width: '100%'
      }
    }
  },
  methods: {
    async getPropiedad (idPropiedad) {
      try {
        const { data } = await HTTP.get('property/' + idPropiedad)
        this.propiedad = data.property
        this.isLoading = false
        console.log({ data })
      } catch (err) {
        console.log(err)
        alert('error')
      }
    }
  },
  mounted () {
    this.getPropiedad(this.$route.params.id)
  },

  filters: {
    money (amount) {
      return accounting.formatMoney(amount)
    }
  }
}
</script>
